import React from "react";
import { graphql, useStaticQuery } from "gatsby";
//import { useLocation } from '@reach/router';

import HeroV8 from "../components/HeroV8";
import FeaturedContent from "../components/FeaturedContent";
import OurServices from "../components/OurServices";
import FeaturedProject from "../components/FeaturedProject";
import ReactLightboxV2 from "../components/ReactLightboxV2";
import TestimonialSliderDark from "../components/TestimonialSliderDark";
import Seo from "../components/Seo";

import {
  serviceFeaturedContent,
  ladbrokeProjectDetails,
  ladbrokesProjectHeader,
  servicesGalleryHeader,
  servicesHeroDetails,
  feedback,
} from "../constants";


  
const Services = () => {

  const data = useStaticQuery(graphql`
    query {
      heroImage: allFile(filter: { name: { in: "services-hero" } }) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(
              formats: AUTO
              layout: CONSTRAINED
              placeholder: BLURRED
              aspectRatio: 1.6
            )
          }
        }
      }
      featuredContentImages: allFile(
        filter: {
          name: {
            in: [
              "dhl-gallery-16"
              "02-harvey-nicholes-chiller-upgrade"
              "12-medivet-project"
            ]
          }
        }
        sort: { fields: name, order: ASC }
      ) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(
              formats: AUTO
              layout: CONSTRAINED
              placeholder: BLURRED
            )
          }
        }
      }
      ladbrokesProjectImages: allFile(
        filter: {
          name: {
            in: [
              "01-ladbrokes-project"
              "02-ladbrokes-project"
              "14-ladbrokes-office"
              "18-ladbrokes-office"
            ]
          }
        }
        sort: { fields: name, order: ASC }
      ) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(
              formats: AUTO
              layout: CONSTRAINED
              placeholder: BLURRED
              aspectRatio: 1.0
            )
          }
        }
      }
      galleryImages: allFile(
        filter: {
          name: {
            in: [
              "05-medivet-project"
              "06-ladbrokes-project"
              "07-medivet-project"
              "12-medivet-project"
              "20-ladbrokes-office"
              "22-ladbrokes-office"
            ]
          }
        }
        sort: { fields: name, order: ASC }
      ) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(
              formats: AUTO
              layout: CONSTRAINED
              placeholder: BLURRED
              aspectRatio: 1.1
            )
          }
        }
      }
    }
  `);

  //console.log("data: ", data);

  const structuredData = (
    <script type="application/ld+json">
      {JSON.stringify([{
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement": [{
          "@type": "ListItem",
          "position": 1,
          "name": "Electrical Installations",
          "item":  "https://intact-electrical.co.uk/services/electrical-installations"
        },{
          "@type": "ListItem",
          "position": 2,
          "name": "EICR and PAT testing",
          "item": "https://intact-electrical.co.uk/services/eicers-and-pat-testing"
        },{
          "@type": "ListItem",
          "position": 3,
          "name": "Wiring services",
          "item": "https://intact-electrical.co.uk/services/wiring-services"
        }]
      },{
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement": [{
          "@type": "ListItem",
          "position": 1,
          "name": "Lighting solutions",
          "item": "https://intact-electrical.co.uk/services/lighting-services"
        },{
          "@type": "ListItem",
          "position": 2,
          "name": "Fuse boards",
          "item": "https://intact-electrical.co.uk/services/fuse-boards"
        },{
          "@type": "ListItem",
          "position": 3,
          "name": "Security solutions",
          "item": "https://intact-electrical.co.uk/services/security-solutions"
        }]
      }])}
    </script>
  )
  

  const featuredContentImages = data.featuredContentImages.nodes;
  const ladbrokeProjectImages = data.ladbrokesProjectImages.nodes;

  const heroBackgroundImage =
    data.heroImage.nodes[0].childImageSharp.gatsbyImageData;

  const {
    buttonLeft,
    buttonLeftLink,
    buttonRight,
    buttonRightLink,
    excerpt,
    titleTop,
    titleBottom,
  } = servicesHeroDetails;

  return (
    <>
      <Seo       
        title="Intact Electrical Services"
        description="Electrical installations, Eicr,  Pat testing, Wiring services, Lighting solutions, Fuse boards, Security solutions, call us today"
        post={false}
        metaRobots="follow, index"
        structuredData={structuredData}
      />
      <HeroV8
        heroImage={heroBackgroundImage}
        heroTitleTop={titleTop}
        heroTitleBottom={titleBottom}
        excerpt={excerpt}
        btnLeft={buttonLeft}
        btnLeftLink={buttonLeftLink}
        btnRight={buttonRight}
        btnRightLink={buttonRightLink}
      />
      <FeaturedContent
        featuredContent={serviceFeaturedContent}
        featuredContentImages={featuredContentImages}
      />
      <OurServices />
      <FeaturedProject
        projectDetails={ladbrokeProjectDetails}
        projectImages={ladbrokeProjectImages}
        projectHeader={ladbrokesProjectHeader}
      />
      <ReactLightboxV2
        images={data.galleryImages.nodes}
        galleryContent={servicesGalleryHeader}
        location="services-page"
      />
      <TestimonialSliderDark feedback={feedback} />
    </>
  );
};

export default Services;
